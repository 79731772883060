
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheFooter',
  setup() {
    return {
      footer: window.__PRE_CONFIG__.footer
    };
  }
});
