
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheHeader',
  setup() {
    const { header, subHeader } = window.__PRE_CONFIG__;
    return {
      header, subHeader
    };
  }
});
